<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

export default {
  extends: Form,
  props: {
    parentCode: String,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tpm_wine_mixing_pool_adjustment',
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'num') { // 调整数量（瓶）
        item.props = {
          ...item.props,
          min: 0,
          precision: 0,
          controls: false,
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    async formComplete() {
      this.disabled(true, ['frontBalance', 'afterBalance']);
      this.setValue({
        frontBalance: this.formConfig.row.remainingNum || 0,
      });
      const frontBalance = this.getRule('frontBalance');
      const operateType = this.getRule('operateType');
      const num = this.getRule('num');
      operateType.on.change = (val) => {
        if (val === 'adjust_add') {
          this.setValue({
            afterBalance: (Number(frontBalance.value) || 0) + (Number(num.value) || 0),
          });
        } else if (val === 'adjust_reduce') {
          this.setValue({
            afterBalance: (Number(frontBalance.value) || 0) - (Number(num.value) || 0),
          });
        }
      };
      num.on.change = (val) => {
        if (operateType.value === 'adjust_add') {
          this.setValue({
            afterBalance: (Number(frontBalance.value) || 0) + (Number(val) || 0),
          });
        } else if (operateType.value === 'adjust_reduce') {
          this.setValue({
            afterBalance: (Number(frontBalance.value) || 0) - (Number(val) || 0),
          });
        }
      };
    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        const url = '/tpm/tpmTastingPoolDetail/tastingPoolAdjust';
        const params = { ...formData, tastingPoolId: this.formConfig.row.id };
        delete params.title1;
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
